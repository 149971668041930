.adWrapper {
  // border-radius: var(--radius-default);
  // min-height: 6rem;
  // // background: #d4d4d4;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // margin-bottom: 0.5rem;
  // padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  height: auto;
  // @media screen and (max-width: 1600px) {
  //   height: 230px;
  // }
  // @media screen and (max-width: 1440px) {
  //   height: 190px;
  // }
  // @media screen and (max-width: 1220px) {
  //   height: 140px;
  // }
  // @media screen and (max-width: 1100px) {
  //   height: 130px;
  // }
  // @media screen and (max-width: 991px) {
  //   height: 180px;
  // }
  // @media screen and (max-width: 767px) {
  //   height: 150px;
  // }
  // @media screen and (max-width: 590px) {
  //   height: 250px;
  // }
  // @media screen and (max-width: 480px) {
  //   height: 200px;
  // }
  // @media screen and (max-width: 375px) {
  //   height: 144px;
  // }

  .img {
    height: 100%;
    width: 100%;
    display: block;
    border-radius: var(--radius-default);
  }
}

.indicatorContentWrp {
  display: none !important;
}
