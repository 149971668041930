.sliderWrapper {
  z-index: 4;
  padding: 0.5rem;
  border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  position: sticky;
  box-shadow: 0 3px 6px #18123136;
  // box-shadow: 0 2px 3px #0000008c;
}

.provinceBtn {
  transform: skewX(-25deg);
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  //   border: 2px solid transparent;
  border-radius: var(--radius-default);
  .btnChild {
    font-weight: 600;
    // color: rgb(111, 113, 122);
    transform: skewX(25deg);
    display: inline-flex;
    align-items: center;
    span {
      &:first-child {
        margin-right: 0.25rem;
      }
      &:last-child {
        // font-size: 1rem;
        padding-top: 2px;
      }
    }
  }
}
