.trackRaceCard {
  display: inline-block;
  margin-right: 0.5rem;
  min-width: 80px;
  min-height: 45px;
  padding: 0.25rem;
  text-align: center;
  transition: 0.3s;
  border-radius: var(--radius-default);
}

.raceNo {
  font-size: 13px;
  font-weight: 600;
  // span:nth-child(2) {
  // }
}

.status {
  font-size: 13px;
  font-weight: 600;
  // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.342);
}
