.header {
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 1rem;
  .firstCol {
    display: flex;
    align-items: center;
  }
  .secondCol {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
  }
}

.logoWrapper {
  padding-right: 1rem;
  img {
    height: 2.75rem;
  }
}

.navLink {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  font-weight: 600;
}
.sideMenuWrp {
  display: none;
}
.navMenu {
  display: inline-flex;
}
.betSlipWrp {
  display: none !important; 
}
@media screen and (max-width: 1024px) {
  .sideMenuWrp {
    display: block;
    // transition: s;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 991px) {
  .navMenu {
    display: none;
  }
  .betSlipWrp {
    display: inline-block !important;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 590px) {
  .logoWrapper {
    img {
      height: 2.3rem;
    }
  }
  .sideMenuWrp {
    display: none;
  }
  .betSlipWrp {
    display: none !important;
  }
}
