@import "/src/styles/mixins/size.mixin.scss";

.btn {
  border: none;
  border-radius: var(--radius-default);
  
}

.elevatedBtn {
  @include size-btn;
}
