.raceSelector {
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.raceSelectorButton {
  transform: skewX(-25deg);
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  //   border: 2px solid transparent;
  border-radius: var(--radius-default);
  .btnChild {
    font-weight: 600;
    color: rgb(111, 113, 122);
    transform: skewX(25deg);
    display: inline-flex;
    align-items: center;
    span {
      &:first-child {
        margin-right: 0.25rem;
      }
      &:last-child {
        // font-size: 1rem;
        padding-top: 2px;
      }
    }
  }
}


