.avatar {
  box-shadow: 2px 2px 6px black;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: inline-block;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .avatar {
    height: 2rem;
    width: 2rem;
  }
}
