.transWrp {
  background-color: #a3a9ca;
  border-radius: 10px;
  position: relative;
}

.tabWrp {
  display: flex;
  align-items: center;
}
.dateWrapper {
  padding: 0 0.3rem 0 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 590px) {
  .tabWrp {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
