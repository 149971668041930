.winnerWrapper {
  overflow: hidden;
  display: inline-block;
  padding: 00.35rem;

  border-bottom: thin solid #9c9c9c48;

  border-radius: 0.65rem;
  //   margin-bottom: 0.25rem;
}

.exoticsTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
}

.noData {
  height: 30vh;
  background: #202020;
  color: gray;
  font-style: italic;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexCol {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  .borderWrapper {
    display: flex;
    padding: 0.35rem;

    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .tableCard {
    .resultTable {
      font-size: 1rem;
      tbody > tr > td {
        &:first-child {
          width: 7rem;
        }
        &:last-child {
          width: 10rem;
        }
      }
    }
  }
}
