.notificationWrapper {
  position: relative;
  display: inline-block;
  background: transparent;
  color: white;

  border-radius: var(--radius-default);
  border: none;
  .dotWrapper {
    position: absolute;
    top: 0;
    right: -5px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    font-size: 8px;
    color: whitesmoke;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
