.header {
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  .firstCol {
    display: flex;
    align-items: center;
  }
  .secondCol {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.logoWrapper {
  padding-right: 1rem;
  img {
    height: 2.5rem;
  }
}

.policyHeader {
  position: sticky;
  top: 0;
}
