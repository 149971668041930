.helpWrapper {
  padding: 0.5rem 1rem;
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.8rem;
    font-weight: 600;
    // border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
  }
  //   .detailsWrapper {
  //     // padding-left: 0.5rem;
  //   }
  h2 {
    padding-bottom: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  ul {
    margin-left: 0.3rem;
    li {
      list-style-type: disc;
      font-size: 1.1rem;
      line-height: 1.9;
      font-family: helvetica;
    }
  }
  .infoWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-items: center;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 1440px) {
  .helpWrapper {
    .infoWrapper {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 590px) {
  .helpWrapper {
    .infoWrapper {
      .imageWrp {
        img {
          height: 100%;
          width: 100%;
          display: block;
        }
      }
    }
    .title {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 0.9rem;
    }
    ul {
      li {
        font-size: 0.7rem;
      }
    }
  }
}
