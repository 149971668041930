// Padding
.p1 {
  padding: 1rem;
}
.p05 {
  padding: 0.5rem;
}
.p025 {
  padding: 0.25rem;
}
.py025 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb1 {
  padding-bottom: 1rem;
}
.pl05 {
  padding-left: 0.5rem;
}

.pr05 {
  padding-right: 0.5rem;
}

// Flex
.flex {
  display: flex;
}

// Margins
.mr05 {
  margin-right: 0.5rem;
}
.ml05 {
  margin-left: 0.5rem;
}
.ml025 {
  margin-left: 0.25rem;
}
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

//text transaform

.capitalized {
  text-transform: capitalize;
}

.bold {
  font-weight: 600;
}

//Shimmer Loader

.card {
  box-shadow: 2px 2px 3px #a9a9a977;
  width: 85px;
  height: 45px;
  // border: 1px solid rgba(128, 128, 128, 0.233);
  border-radius: var(--radius-default);
}
.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.profilePic {
  width: 85px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite linear;
  // background: linear-gradient(to right, #7a80b3 4%, #5c6191 25%, #7a80b3 36%);
  background: linear-gradient(to right, #262a53 4%, #111434 25%, #1b2850 36%);
  background-size: 1000px 100%;
  &.mediumLight {
    background: linear-gradient(to right, #7a80b3 4%, #5c6191 25%, #7a80b3 36%);
    background-size: 1000px 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
