.tableHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  padding: 0 0.25rem;
  button.headerBtn,
  button.poolHeaderBtn {
    background: transparent;
    border: none;
    padding: 0.5rem 0.5rem;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
}

.poolHeader {
  display: grid;
  grid-template-columns: repeat(4, var(--width-selection));
  column-gap: var(--pool-col-gap);
}

.exPoolHeader {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 0.25rem 0;
}

@media screen and (max-width: 480px) {
  .tableHeader {
    button.headerBtn,
    button.poolHeaderBtn {
      font-size: 0.5rem;
    }
  }
}
@media screen and (max-width: 325px) {
  .tableHeader {
    button.headerBtn,
    button.poolHeaderBtn {
      font-size: 0.5rem;
    }
  }
}
