.headerWrapper {
  display: flex;
  align-items: center;
  padding: 0.25rem 0 0.25rem 0.75rem;
}

.carouselWrapper {
  overflow: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  .carouselChild {
    display: flex;
    align-items: center;
  }
}

.raceSnapCard {
  // border-right: thin solid yellow;
  position: relative;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;

  white-space: nowrap;
  min-width: 14rem;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   transform: translate(50%, 50%);
  //   height: 1.75rem;
  //   width: 2px;
  //   background: rgb(184, 163, 125);
  //   // border: 1px solid wheat;
  // }
  .infoWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    .trackInfo {
      width: 100%;
      padding: 0 0.5rem;
      text-transform: capitalize;
      .trackName,
      .raceNo {
        font-size: 0.8rem;
      }
      .raceNo {
        color: rgb(102, 255, 217);
        font-weight: 600;
      }
    }
  }
  .footerWrapper {
    .provinceInfo {
      display: flex;
      align-items: center;
      font-size: 0.6rem;
      span {
        margin-right: 0.25rem;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .statusWrapper {
      font-size: 0.65rem;
      border-radius: 0.25rem;
      background: red;
      padding: 0 0.25rem;
    }
  }
}

.actionBtnWrapper,
.actionBtnWrapperFront {
  position: sticky;
  right: 0;
  display: flex;
  flex-direction: column;
  button {
    background: transparent;
    border: none;
  }
}
.actionBtnWrapperFront {
  left: 0;
  right: auto;
}

.filterWrapper {
  padding: 0.25rem 0.5rem;
  border-right: 1px solid wheat;
}

@media screen and (max-width: 480px) {
  .raceSnapCard {
    padding: 0.5rem;
  }
}
