.selectorWrapper {
  position: relative;
}

.selectorBtn {
  background: transparent;
  padding: 0.25rem;
  border: none;
  border-radius: var(--radius-default);
  display: inline-flex;
  align-items: center;
  text-align: left;
}

.trackInfo {
  padding-left: 0.5rem;
  .trackName {
    font-size: 1.25rem;
    font-weight: 600;
    padding-right: 0.5rem;
  }
}

.trackOptionsWrapper {
  position: absolute;
  top: 100%;
  max-height: 0;
  overflow: auto;
  min-width: 200px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.586);
  left: 0;
  opacity: 0;
  z-index: 20;
  transition: 0.3s;

  &.show {
    max-height: 300px;
    opacity: 1;
  }
  .optionBtn {
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
  }
}

@media screen and (max-width: 425px) {
  .trackInfo {
    .trackName {
      font-size: 0.9rem;
    }
  }
}
