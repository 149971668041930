.header {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0rem;
}

.headerWrp {
  display: flex;
  justify-content: space-between;
}

