.trackRacesWrapper {
  // padding: 0.5rem 0;
  position: relative;
  z-index: 3;
}

.trackName,
.raceList {
  padding: 0.25rem 0rem;
  white-space: nowrap;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.province {
  border-radius: 3rem;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-right: 0.5rem;
  span:last-child {
    padding-left: 0.25rem;
  }
}


.provinceTracksWrapper {
  position: relative;
  margin-bottom: 1rem;

  .separator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2rem;
    background: linear-gradient(#787cb470, #00000000);
  }
  // &:not(:first-child) {
  //   border-top: 2px solid #d03d4d ;
  // }
}

@media screen and (max-width: 590px) {
  .sliderWrapper {
    top: var(--height-header) !important;
  }
}
