.termsAndUseWrapper {
  padding: 0.5rem 1rem;
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.8rem;
    font-weight: 600;
    // border-bottom: 1px solid black;
    padding-bottom: 0.2rem;
  }
  h2 {
    font-family: Arial, Helvetica, sans-serif;
  }
  .detailsWrapper {
    line-height: 1.5;
    padding-top: 0.5rem;
    p {
      font-family: helvetica;
      text-align: justify;
      padding-bottom: 1rem;
    }
  }
}
.noContentWrp {
  padding-left: 1rem;
  padding-bottom: 1rem;
  .li {
    list-style-type: decimal;
    font-family: helvetica;
  }
}

@media screen and (max-width: 590px) {
  .termsAndUseWrapper {
    .title {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 0.85rem;
    }
    .detailsWrapper {
      p {
        font-size: 0.8rem;
      }
    }
    .noContentWrp {
      .li {
        font-size: 0.9rem;
      }
    }
  }
}
