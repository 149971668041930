.localeChangerWrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  color: var(--text-primary);
  align-items: center;
  justify-content: center;
  .localeBtn {
    // background: transparent;
    border: none;
  }
  .localeOptionsWrapper {
    position: absolute;
    top: 130%;
    right: 0;
    min-width: 100px;
    z-index: 101;
    border: var(--border-primary-thin);
    border-radius: 0.25rem;
    box-shadow: 0 0 3px black;
    .localeOption {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.5rem;
      text-decoration: none;
      font-size: 1rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      span {
        margin-right: 0.5rem;
      }
      transition: 0.3s;
    }
  }
}
// .localeOption i::after {
//   content: "";
//   position: absolute;
//   width: 12px;
//   height: 12px;
//   left: 50%;
//   transform: translate(-50%, 50%) rotate(45deg);
//   background-color: #000066;
//   box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
// }
.btnWrp {
  cursor: pointer;
}

.langIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .label {
    display: none;
  }
  .langIcon {
    display: none;
  }
}
