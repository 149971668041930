.footer {
  margin-top: 2rem;
}
.copyrightWrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0;
}
.footWrapper {
  min-height: 250px;
  display: grid;
  grid-template-columns: 35% 1fr;
  .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 1rem;
    .ChildWrapper {
      padding-top: 3rem;
    }
  }
  .linkWrp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 1.7rem;
    div {
      line-height: 2;
      font-size: 0.9rem;
    }
  }
}

.policyFooter {
  position: sticky;
  bottom: 0;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  margin: auto;
  z-index: 99;
  @media screen and (max-width: 480px) {
    font-size: 0.65rem;
    flex-direction: column;
  }
}

.linksWrapper {
  color: #c7c8c8;
  .link {
    color: inherit;
    margin: 0 0.75rem;

    text-decoration: underline;
  }
}

@media screen and (max-width: 1200px) {
  .footWrapper {
    .mainWrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 991px) {
  .footWrapper {
    .imgWrapper {
      img {
        height: 66px;
      }
    }
    .mainWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 590px) {
  .footWrapper {
    grid-template-columns: 1fr;
    padding: 1rem;
    // .imgWrapper {
    //   img {
    //     height: 65px;
    //     width: 150px;
    //   }
    // }
    .linkWrp {
      div {
        font-size: 0.67rem;
      }
    }
  }
  .copyrightWrp {
    font-size: 0.8rem;
  }
}
