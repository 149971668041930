.input {
  padding: 1rem;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 1.5rem;
  background-color: white;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--color-ternary),
      0 1px 2px 0 rgba(255, 255, 255, 0.506);
  }
  &:disabled {
    border-color: rgb(187, 187, 187);
  }
}
.dropdownWrapper {
  position: relative;
}
// .label {
//   padding-left: 1rem;
// }

// .options {
//   color: rgb(252, 252, 252);
//   font-size: 1.5rem;
// }

.buttonIcon {
  position: absolute;
  top: 95%;
  right: 0rem;
  transform: translate(-13px, -39px);
  font-size: 2rem;
  cursor: pointer;
  color: grey;
}

.optionWrapper {
  display: none;
  background-color: white;
  position: absolute;
  top: 97%;
  left: 0;
  right: 0;
  // height:115px;
  z-index: 101;
  border: 1px solid;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.164);
  border: transparent;
}
.shown {
  display: block;
}
.dropdownButton {
  padding: 1rem;
  font-size: 1.2rem;
  // border: var( --border-color-primary) ;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: black;
  display: flex;
  align-items: center;
  text-align: left;
  &:hover {
    color: white;
    background-color: #262a53;
  }
  .curName {
    padding-left: 0.5rem;
  }
}

.disabled {
  .buttonIcon {
    color: gray;
  }
  opacity: 0.2;
}

// @media screen and (max-width: 590px) {
//   .mainWrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
