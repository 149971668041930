.headerWrp {
  gap: 1rem;
  display: flex;
  padding: 0.5rem 1rem;
  overflow: hidden;
}

@media screen and (max-width: 590px) {
  .headerWrp {
    display: none;
  }
}
