.et24 {
  background-image: url("24x24_eventType.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.et32 {
  background-image: url("32x32_eventType.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 32px;
  height: 32px;
}

.et44active {
  background-image: url("44x44_Active_eventTypes.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 44px;
  height: 44px;
}

.et48 {
  background-image: url("48x48_eventType.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 48px;
  height: 48px;
}

// * Positions for 44x44 --- START
// Active Colors
.et44active.TH {
  background-position: 0px 0px;
}
.et44active.HS {
  background-position: -44px 0;
}
.et44active.DO {
  background-position: -88px 0;
}
// * Positions for 44x44 --- END

// * Positions for 24x24 --- START
// Active Colors
.et32.TH-active {
  background-position: 0px 0px;
}
.et32.HS-active {
  background-position: -24px 0;
}
.et32.DO-active {
  background-position: -48px 0;
}

.et32.TH-black {
  background-position: -0px -24px;
}
.et32.HS-black {
  background-position: -24px -24px;
}
.et32.DO-black {
  background-position: -48px -24px;
}

.et32.TH-white {
  background-position: -0px -48px;
}
.et32.HS-white {
  background-position: -24px -48px;
}
.et32.DO-white {
  background-position: -48px -48px;
}

// * Positions for 24x24 --- END

// * Positions for 32x32 --- START
// Active Colors
.et32.TH-active {
  background-position: 0px 0px;
}
.et32.HS-active {
  background-position: -32px 0;
}
.et32.DO-active {
  background-position: -64px 0;
}

.et32.TH-black {
  background-position: -0px -32px;
}
.et32.HS-black {
  background-position: -32px -32px;
}
.et32.DO-black {
  background-position: -64px -32px;
}

.et32.TH-white {
  background-position: -0px -64px;
}
.et32.HS-white {
  background-position: -32px -64px;
}
.et32.DO-white {
  background-position: -64px -64px;
}

// * Positions for 32x32 --- END

// * Positions for 48x48 --- START
// Active Colors
.et48.TH-active {
  background-position: 0px 0px;
}
.et48.HS-active {
  background-position: -48px 0;
}
.et48.DO-active {
  background-position: -96px 0;
}

.et48.TH-black {
  background-position: -0px -48px;
}
.et48.HS-black {
  background-position: -48px -48px;
}
.et48.DO-black {
  background-position: -96px -48px;
}

.et48.TH-white {
  background-position: -0px -96px;
}
.et48.HS-white {
  background-position: -48px -96px;
}
.et48.DO-white {
  background-position: -96px -96px;
}

// * Positions for 48x48 --- END
