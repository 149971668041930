.statusWrapper {
  padding: 0.25rem;
  border-radius: var(--radius-default);
  font-weight: 500;
}

@media screen and (max-width: 425px) {
  .statusWrapper {
    font-size: 0.8rem;
  }
}
