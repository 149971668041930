.status,
.timer {
  font-size: 12px;
  font-weight: 600;
  border-radius: var(--radius-default);
  padding: 0.2rem 0.25rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.514);
  display: inline-block;
  text-align: center;
  min-width: 2.5rem;
}
.selectRaceTimerContainer {
  display: flex;
  background: transparent;
  overflow: hidden;
  .statusWrapper {
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .selectRaceTimer {
    font-size: 1rem;
    font-weight: 600;
    // border-top-right-radius: var(--radius-default);
    // border-bottom-right-radius: var(--radius-default);
    padding: 0.35rem 0.35rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.514);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
  }
}

@media screen and (max-width: 590px) {
  .selectRaceTimerContainer {
    .statusWrapper {
      padding: 0.25rem;
    }
  }
}

@media screen and (max-width: 425px) {
  .selectRaceTimerContainer {
    .statusWrapper {
      display: none;
    }
  }
}
