.slipBody {
  max-height: calc(100vh - 120px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.slipFooter {
  text-align: right;
}
