.Hide {
    overflow: hidden;
    height: 0;
    transition: all 0.2s ease-in-out ;
  }
  
  .show {
    height: 30vh;
    transition: all 0.2s ease-in-out ;
  
  }
  
  .arrowIcon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate( 50%,-10%);
  }
  
  .detailCard {
    padding: 0.5rem;
    position: relative;
  }
  .detailCardBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
  }
  
  .detailWrapper {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .detailLabel {
    font-weight: 600;
    font-size: 0.9rem;
  }
  
  .detailData {
    font-size: 0.7rem;
    margin-top: .3rem;
   
  }
  
  .detailCardHeader {
    border-bottom: 1px solid;
    padding: 0.5rem .5rem;
  }
  
  @media screen and (max-width:1600px) {

    .arrowIcon{
    transform: translate(50%,-8%);
  
    }
  }


  @media screen and (max-width:1366px) {
  
    .show{
      height: 30vh;
    }
    .arrowIcon{
    transform: translate(50%,-8%);
  
    }
  }
  
  @media screen and (max-width:600px) {
  
    .show{
      height: 20vh;
    }
    .arrowIcon{
      transform: translate(50%,-6%);
    
      }
  }
  @media screen and (max-width:425px) {
    .show{
      height: 45vh;
    }
    .arrowIcon{
      transform: translate(50%,-5%);
    
      }
  }
  
  @media screen and (max-width:376px) {
    .show{
      height: 50vh;
    }
    .arrowIcon{
      transform: translate(50%,-4%);
      }
  }
  