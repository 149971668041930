.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  padding: 1rem;
  min-height: 3rem;
  height: 100%;
  font-size: 1.2rem;
  transition: 0.3s;
  // border: 2px solid red;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  color: black;

  &.withIcon {
    padding-left: 2.35rem;
  }

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--color-ternary),
      0 1px 2px 0 rgba(255, 255, 255, 0.506);
  }
}

.icon {
  position: absolute;
  left: 0;

  top: 0;
  bottom: 0;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  color: grey;
}

@media screen and (max-width: 480px) {
  .input {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
  .icon {
    font-size: 0.8rem;
  }
}
