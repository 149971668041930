.raceTipComponent {
  display: block;
  margin: 0 auto;
}

.tipHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem 0.25rem 1rem;
  font-size: 0.8rem;
}

.runnerInfo {
  display: inline-flex;
  align-items: center;
  font-size: 0.85rem;
}
.silkWrapper,
.inlineSilks {
  height: 25px;
  width: 25px;
  background: white;
  border-radius: var(--radius-default);
  // padding: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.tipTitleCard {
  display: inline-flex;
  padding: 0.25rem 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 3.5rem;
  // padding: 0 0.5rem;
  .logoWrapper {
    height: 1.15rem;
    padding-right: 0.25rem;
    img {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
}

.inlineWrapper {
  display: none;
  padding: 0.25rem;
  white-space: nowrap;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 991px) {
    display: flex;
  }
}

.inlineSilks {
  height: 20px;
  width: 20px;
  background: white;
}

.inlineTipCard {
  display: inline-flex;
  align-items: center;
  border-radius: var(--radius-default);
  padding: 0.25rem;
  margin-right: 0.25rem;
}
