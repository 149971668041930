.tableDiv {
  position: relative;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  min-height: 20rem;
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(202, 239, 255, 0.554);
  min-height: 20rem;
  z-index: 100;
}
