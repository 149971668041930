.cards {
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

$col: 40px;

.raceCardWrapper {
  border-radius: var(--radius-default);
  padding: 0.25rem;
  .raceCard {
    display: grid;
    transition: 0.3s;
    grid-template-columns: $col auto $col;
    .col:first-child {
      justify-self: center;
      text-align: center;
    }
    .col:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .raceNo {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.452);
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    .trackInfo {
      margin-left: 0.5rem;
      .trackName {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 600;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.452);
        padding-bottom: 0.45rem;
        // padding-top: 0.15rem;
      }

      display: inline-block;
    }
    .provinceWrapper {
      font-size: 10px;
      display: flex;
      align-items: center;
      span:last-child {
        margin-left: 0.25rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
