.tableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  align-items: flex-end;
  padding: 0 0.25rem;
  &.bordered {
    border-right: 1px solid gray;
  }

  button.headerBtn,
  button.poolHeaderBtn {
    background: transparent;
    border: none;
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    font-weight: 600;
  }
  .legTitle {
    font-size: 0.85rem;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(15, 5, 5, 0.336);
    align-self: center;
  }
}

.poolHeader {
  display: grid;
  // grid-template-columns: repeat(4, var(--width-selection));
  column-gap: var(--pool-col-gap);
}

@media screen and (max-width: 480px) {
  .tableHeader {
    button.headerBtn,
    button.poolHeaderBtn {
      font-size: 0.5rem;
    }
  }
}
@media screen and (max-width: 325px) {
  .tableHeader {
    button.headerBtn,
    button.poolHeaderBtn {
      font-size: 0.5rem;
    }
  }
}
