.logoWrapper {
  position: absolute;
  bottom: 2rem;
  //   border: 1px solid green;
  right: 1rem;
  z-index: 5;
  overflow: hidden;

  .logoWidth {
    display: inline-block;
    position: relative;
    // border: 1px solid orange;
    // width: 100px;
    height: 40px;
    left: 78%;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      left: 0;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
