.navbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 0;
  transition: 0.4s;
  z-index: 1000;
  &.active {
    width: 30%;
  }
  .headWrp {
    padding: 0.7rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .closeIcnWrp {
    &:hover {
      color: red;
    }
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(54, 61, 63, 0.219);
  z-index: 99;
}
@media screen and (max-width: 768px) {
  .navbar {
    &.active {
      width: 45%;
    }
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    &.active {
      width: 70%;
    }
  }
}
