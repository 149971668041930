.snackBarWrapper {
  padding: 0.5rem;
  font-size: 0.8rem;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.324);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
}

@media screen and (max-width:590px) {
  .snackBarWrapper{
    font-size: 0.6rem;
  }
}