.walletWrapper {
  display: flex;
  justify-content: space-around;
  height: 35px;
  .iconWrp {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    min-width: 32px;
    padding: 0 0.5rem;
    border-top-right-radius: calc(var(--radius-default) - 2px);
    border-bottom-right-radius: calc(var(--radius-default) - 2px);
    
  }
  .balanceWrapper {
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    // color: #7dec62;
    .curWrp {
      padding-left: 0.2rem;
    }
  }
}

.positive {
  animation: update;
  animation-duration: 0.5s;
}

.negative {
  animation: update-negative;
  animation-duration: 0.5s;
}

@keyframes update-negative {
  0% {
    scale: 1;
    color: white;
  }
  50% {
    scale: 1.1;
    color: #f16170;
  }
  0% {
    scale: 1;
    color: white;
  }
}

@keyframes update {
  0% {
    scale: 1;
    color: white;
  }
  50% {
    scale: 1.1;
    color: #7dec62;
  }
  0% {
    scale: 1;
    color: white;
  }
}


@media  screen and (max-width: 480px) {
    .icon {
      display: none;
    }
}