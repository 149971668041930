.footerWrapper {
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding: 0.25rem 0.5rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.footerButtons {
  button {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 590px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .footerButtons {
    button {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
  }
}

.multiFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.leftButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiFooterButtons {
  display: flex;
  align-items: center;
}

.addBetButton {
  margin-left: 0.5rem;
}

.quickBetBtn {
  &:disabled {
    color: gray;
  }
}

.multiBetInputWrapper {
  display: flex;
}
.statusMessage {
  font-size: 0.8rem;
  font-weight: 600;
  &.success {
    color: #7aff00;
  }
  &.error {
    color: rgb(250, 13, 13);
  }
}

.error {
  font-size: 0.8rem;
  color: rgb(250, 13, 13);
}
@media screen and (max-width: 1440px) {
  .rightButtons {
    justify-content: space-around;
    padding: 0.5rem;
  }

  .multiFooter {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .leftButtons {
    flex-direction: column;
  }

  .multiBetInputWrapper {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 425px) {
  .textShow {
    font-size: 0.7rem;
  }

  .rightButtons {
    padding: 0;
  }
}
