.tooltip {
  display: inline-flex;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
}

.trackName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 7rem;
}

.tooltip .bottom {
  min-width: 150px;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 5px;

  //   background-color: #000066;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  text-align: center;
  text-overflow: ellipsis;
}

.tooltip:hover .bottom {
  display: block;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #000066;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
