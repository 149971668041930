body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button,
a {
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
}

:root {
  --selection-box: 2rem;
  @media screen and (max-width: 480px) {
    --selection-box: 29px;
  }
  --width-selection: 3.5rem;
  --radius-default: 0.35rem;
  --height-header: 4.25rem;
  --pool-col-gap: 0.5rem;
  --transition-default: 0.35s;
  @media screen and (max-width: 590px) {
    --height-header: 2.5rem;
  }
  @media screen and (max-width: 480px) {
    --width-selection: 2.5rem;
  }
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  background-color: #c9d0de;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: white !important;
}

.DatePicker_goCalenderContainer__5REk2 {
  background: #262a53;
  cursor: pointer;
  color: white;
}

.DatePicker_goCalenderContainer__5REk2:hover {
  background: #262a53 !important;
}

.DatePicker_goCalenderContainer__5REk2 {
  border-radius: var(--radius-default);
}

.p-inputtext,
.p-dropdown-trigger {
  padding: 0.65rem !important;
}
.p-paginator {
  padding: 0rem !important;
}
.p-paginator .p-dropdown {
  height: auto !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  height: 2.5rem !important;
  min-width: 2.5rem !important;
}

*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #6a7fa1;
  outline: 1px solid #182945;
  border-radius: 8px;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  display: none !important;
}
.p-paginator {
  border-radius: 0px 0px 10px 10px !important;
  background-color: transparent !important;
  color: white !important;
}

.p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #262a53 !important;
  color: white !important;
}

.p-paginator .p-paginator-first {
  color: white !important;
}
.p-paginator .p-paginator-last {
  color: white !important;
}

.p-paginator .p-paginator-prev {
  color: white !important;
}
.p-paginator .p-paginator-next {
  color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: blue !important;
}
.p-datatable .p-sortable-column.p-highlight {
  background-color: #262a53 !important;
  color: white !important;
}

.goBadge {
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 600;
  background: #ffffff;
  vertical-align: text-top;
}
.round {
  height: 2rem;
  width: 2rem;
  border-radius: 5rem;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

$bodyContrast: #262a53;
.dialogHeader,
.dialogContent {
  background: $bodyContrast !important;
}
.dialogFooter {
  background: $bodyContrast;
  // border-radius: ;
}

.p-dialog .p-dialog-header {
  color: white !important;
}
