.animWrapper {
  position: relative;
  border-radius: 50%;
  color: #101010;
  &.active {
    background: var(--text-secondary);
  }
  &.sm {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.lg {
    height: 2.75rem;
    width: 2.75rem;
    font-size: 1rem;
  }
}
.overlay {
  position: absolute;
  top: 0;
  z-index: 6;
  left: 0;
  right: 0;
  border-radius: 50%;
  bottom: 0;

  transform: scale(0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &.light {
    box-shadow: 0 0 10px #dfdfdf;
    background: radial-gradient(#ffc702, #dfdfdf);
  }
  &.dark {
    background: radial-gradient(circle, #ffc702, #b9b9b9);
    box-shadow: 0 0 7px #d4c17f;
  }
}
.hide {
  transform: scale(1.9);
  opacity: 1;
}
@keyframes animScale {
  0% {
    transform: scale(1.75);
    opacity: 1;
  }
  25% {
    transform: scale(1.5);
    opacity: 0.75;
  }
  50% {
    transform: scale(1.25);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
