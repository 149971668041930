.metaWrapper {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.details {
  white-space: nowrap;
  display: flex;
  padding-right: 1rem;
  padding-left: 0.5rem;
  border-right: 1px solid gray;
  .iconWrapper {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
  }
  //   justify-content: center;
  .dataWrapper {
    display: inline-flex;
    flex-direction: column;
    .label {
      font-size: 0.65rem;
      line-height: 0.75rem;
    }
    .data {
      font-weight: 600;
      font-size: 0.75rem;
      text-transform: capitalize;
    }
  }
}
