.selectionBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: var(--selection-box);
  width: var(--selection-box);
  border-radius: var(--radius-default);
  border: none;
}
