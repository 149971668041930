.welcomeWrapper {
  // height: 400px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
  .childWrp {
    padding: 0.8rem;
  }
  p {
    font-size: 0.8rem;
    padding: 0.5rem 0;
  }
}
