.switchWrapper {
  display: inline-flex;
  align-items: center;
  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.25rem;
  }
}
.sun{
  color: #ffe600;
}
.moon{
  color: #ffff;
}
@media screen and (max-width:480px) {
  .switchWrapper{
    label{
      font-size: 0.7rem;
    }
  }
}