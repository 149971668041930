.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  border-bottom: thin solid #69696979;
  .raceInfo {
    margin: 0;
    display: flex;
    .trackInfo {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      span {
        font-size: 1rem;
        color: #adadad;
        // padding-left: 0.25rem;
      }
    }
  }
  .tidWrapper {
    display: flex;
    justify-content: flex-end;
    color: #a4b8c5;
    font-style: italic;
  }
}
.infoTab {
  border-bottom: thin solid #69696979;
  background: #37373862;
  display: flex;
  justify-content: space-between;
}
.detailsWrapper {
  display: inline-flex;
  flex-direction: column;
  padding: 0.25rem 1rem;
  font-size: 0.9rem;
  height: 100%;
  justify-content: center;
  &:not(:last-child) {
    border-right: thin solid #5d616479;
  }
  label {
    font-style: italic;
    color: #ffffff;
    font-weight: 600;
    padding-bottom: 0.3rem;
  }
}

.invDetails {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: thin solid #3d3d3d86;
  .detailsWrapper {
    padding-left: 0rem;
    // border: none;
    align-items: center;
    text-align: center;
  }
}

.positive {
  font-size: 1.5rem;
  color: #0ffa2e;
}
.negative {
  font-size: 1.5rem;
  color: #fc4423;
}

.winningsInfo {
  padding: 0.5rem 0;
  border-bottom: thin solid #3d3d3d86;

  label {
    font-style: italic;
    color: #a4b8c5b7;
    font-weight: 600;
  }
  span {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .headerWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .invDetails {
    grid-template-columns: 1fr;
  }
  .infoTab {
    display: grid;
    grid-template-columns: 1fr;
    .detailsWrapper {
      display: flex;
      height: auto;
    }
  }
}
