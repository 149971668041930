.betCardWrapper {
  border-radius: var(--radius-default);
  overflow: hidden;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .betCardHeader {
    display: flex;
    padding: 0.25rem;
    // align-items: center;
    justify-content: space-between;

    .raceInfo {
      display: inline-flex;
      font-size: 0.7rem;
    }
    .trackName {
      font-weight: 600;
      font-size: 0.7rem;
    }

    .closeBtn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: rgba(94, 115, 160, 0.534);
      color: rgb(255, 101, 101);
      height: 20px;
      width: 20px;
      border: none;
      border-radius: var(--radius-default);
      &:hover {
        background: red;
        color: white;
      }
    }
  }
}

.runnerInfo {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  text-shadow: 1px 1px 3px #1a1a1ab0;
  .selId {
    border-radius: var(--radius-default);
    height: 1.25rem;
    width: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.betFooter {
  display: flex;
  padding: 0.3rem;
  border-radius: var(--radius-default);
  overflow: hidden;
  padding-top: 1.5rem;
  gap: 0.25rem;
  //   background: red;
  .detailWrapper {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 0 0.25rem;
    white-space: nowrap;
    width: 100%;
    // &:first-child {
    //   border-top-left-radius: var(--radius-default);
    //   border-bottom-left-radius: var(--radius-default);
    // }

    span {
      font-size: 0.6rem;
      position: absolute;
      bottom: 100%;
      padding-bottom: 0.2rem;
    }
    &.small {
      width: 100px;
    }
  }
}

.betBtn {
  border: none;
  padding: 0 0.25rem;
  font-weight: 500;
  // border-top-right-radius: var(--radius-default);
  // border-bottom-right-radius: var(--radius-default);
  border-radius: var(--radius-default);
}

.oddsWrapper {
  border-top-left-radius: var(--radius-default);
  border-bottom-left-radius: var(--radius-default);
}

.exoticsBody {
  padding: 0.5rem;
  .positionsWrapper {
    font-size: 0.8rem;
    .positionVal:not(:last-child)::after {
      content: ", ";
    }
  }
}

.exoticsFooter {
  margin: 0.35rem;
  border-radius: var(--radius-default);
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.exoticsCardBody {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  .section1 {
    padding-bottom: 0.25rem;
  }
  .section2 {
    padding-top: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.openBetsBody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // background-color: rgb(34, 34, 34);
  border-radius: 0.5rem;
  width: 100%;
  // padding: 0.5rem;
  padding-bottom: 0.5rem;

  .openBetsCard {
    // background-color: #353535;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;

    .cardHeader {
      border-radius: 0.5rem;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: #ffffff;
      font-size: 0.6rem;
      .icon {
        width: 40px;
        height: 40px;
      }
      .headerText {
        padding-left: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        .header {
          font-size: 0.8rem;
          font-weight: 600;
          text-transform: capitalize;
        }

        .countryWin {
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
    .cardBody {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding: 0.5rem;
      font-size: 0.8rem;

      .itemWrapper {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        .heading {
          font-size: 0.6rem;
          // font-weight: 600;
          color: var(--text-secondary);
        }

        &.center {
          justify-content: center;
        }

        .right {
          justify-content: center;
        }

        .headingRunner {
          text-align: center;
          width: 100%;
        }
        .headingAmount {
          text-align: center;
          width: 100%;
        }
        .headingPoolType {
          text-align: center;
          width: 100%;
        }

        .runner {
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 8rem;
        }

        .amount {
          text-align: center;
        }

        .poolType {
          padding: 0 1rem;
          // background-color: #35353506;
          border-radius: 0.5rem;
          text-align: center;
        }
      }
    }
  }
}
