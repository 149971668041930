.selectedRaceWrapper {
  position: relative;
  min-height: 450px;
}
.selectedRaceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default);
}
