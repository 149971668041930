.input {
  padding: 0.25rem;
  border-radius: var(--radius-default);
  outline: none;
  position: relative;
  border: none;
}

.betAmtInputWrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: var(--radius-default);

  .label {
    position: absolute;
    bottom: 100%;
    left: 0.5rem;
    z-index: 12;
    transition: 0.3s;
  }
}

.suffix {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 2rem;
  display: inline-flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  border-top-right-radius: calc(var(--radius-default) - 1px);
  border-bottom-right-radius: calc(var(--radius-default) - 1px);
  font-weight: 600;
}

@media screen and (max-width: 425px) {
  .input {
    width: 80%;
  }
}
