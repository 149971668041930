.sectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .socialsWrapper {
    display: flex;
    padding-top: 1rem;
  }
  .textStyle {
    font-size: 4rem;
    padding-bottom: 1rem;
  }

  .socialButton {
    border: 1px solid grey;
    background: transparent;
   margin: 1rem 0.5rem;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
  }

  .numberWrp {
    display: grid;
    padding: 1rem 0;
    grid-template-columns: 6rem auto auto;
    column-gap: 0.5rem;
  }

  .captcha {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.formWrapper {
  padding: 1rem;
  margin-top: 1rem;
}
.wrapper {
  text-align: center;
}
.logo {
  margin-bottom: 2rem;
}
.title {
  margin-bottom: 1rem;
}
.title {
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .logo {
    height: 3rem;
  }
  .title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 1rem;
  }
  .loginWrapper {
    .socialButton {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
