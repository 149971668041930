.inputWrapper {
  position: relative;
  width: 100%;
  display: inherit;
  height: 100%;
  .label {
    position: absolute;
    bottom: 100%;
    left: 0.5rem;
    padding-bottom: 0.25rem;
  }
  input {
    padding: 0.5rem 0.5rem;
    border-radius: var(--radius-default);
    border: none;
    width: 100%;
    // outline: none;
  }
}
