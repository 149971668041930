.noBetSlipWrp {
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text2 {
    font-size: 0.8rem;
  }
}
