.modelOpenBtn {
  //   @include flat-btn;

  color: #4d82f5;
  // padding: 0.5rem 0;
  display: inline-block;
  font-weight: 600;
  background: transparent;
  border: none;
  font-size: 1rem;
  transition: 0.3s;
  // text-align: left;
  &:hover,
  &:focus {
    color: var(--color-golden);
  }
}
.tableWrapper {
  overflow: auto;
}
.transTable {
  width: 100%;
  border-top: 3px solid #272333;
  tr > th,
  tr > td {
    text-align: center;
    max-width: 15rem;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 1rem;
    text-overflow: ellipsis;
    border-bottom: thin solid #16161610;
    &:not(:first-child) {
      border-left: thin solid #2727273b;
    }
  }
}

.raceNo {
  font-weight: 600;
  text-align: center;
}
.negative {
  color: red;
}

.positive {
  color: green;
}
