.rowDiv {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 0.25rem;
  position: relative;
  width: 100%;
}

.rowGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  &.bordered {
    border-right: 1px solid gray;
  }
}

.detailsDiv {
  width: 100%;
}

.rowDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailWrapper {
  // transition: all 0.3s ease-in-out;
}
