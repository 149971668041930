@mixin size-btn {
  &.xs {
    font-size: 0.65rem;
    padding: 0.25rem 0.35rem;
  }
  &.sm {
    font-size: 0.85rem;
    padding: 0.35rem 0.5rem;
  }
  &.default {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }
  &.md {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }
  &.lg {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
  &.xl {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
}
