.goCalenderContainer {
  position: relative;
  display: inline-block;
  transition: 0.3s;
  border-radius: var(--radius-default);
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  .calIcon {
    margin-left: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  .inputStyle {
    // background-color: red !important;
    background: transparent;
    color: var(--text-dark);
    border: none;
    // width: 15rem !important;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }
  .p-datepicker {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .absCalender {
    right: 0;
  }
}
