.horseInfo {
  // border: 1px solid gray;
  display: flex;
}
.scr {
  text-decoration: line-through;
  color: red;
}
.runnerInfo {
  font-size: 0.65rem;
  .infoWrapper {
    padding: 0 10px 0 0;
    text-transform: capitalize;
    &:not(:first-child) {
      padding-left: 1rem;
    }
    &:not(:last-child) {
      border-right: 2px solid rgba(128, 128, 128, 0.473);
    }
    font-weight: 600;
    label {
      font-weight: 500;
    }
  }
}
.silkWrapper {
  background: white;
  overflow: hidden;
  margin: 0.25rem;
  border-radius: var(--radius-default);
  height: 32px;
  width: 32px;
  img {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.horseNameWrp {
  font-size: 0.95rem;
  font-weight: 600;
}

@media screen and (max-width: 590px) {
  .runnerInfo {
    display: none;
  }
  .horseInfo {
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .horseNameWrp {
    font-size: 0.7rem !important;
  }
  .silkWrapper {
    height: 26px;
    width: 26px;
  }
}
