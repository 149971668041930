.noDataWrapper,
.noRaceDataWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}
.noDataWrapper {
  min-height: 150px;
  font-size: 1.25rem;
}

.noRaceDataWrapper {
  min-height: 50vh;
  flex-direction: column;
}
