.profileWrapper {
  padding: 0.5rem;
  position: relative;
  .profileInfo {
    span {
      display: block;
      padding-left: 0.25rem;
      line-height: 1rem;
      font-size: 1rem;
      font-weight: 600;
      &:last-child {
        font-size: 0.7rem;
        padding-left: 0.4rem;
        padding-top: 0.15rem;
      }
    }
  }
  .dropDownWrp {
    position: absolute;
    top: 100%;
    right: 0px;
    left: 0px;
    min-width: 100px;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom-left-radius: var(--radius-default);
    transition: 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    white-space: nowrap;
    .infoWrp {
      display: none;
    }
  }
  .profileInfoWrp {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .profileWrapper {
    .profileInfo {
      display: none;
    }
    .dropDownWrp {
      top: 50px;
      left: auto;
      border-radius: var(--radius-default);

      .infoWrp {
        padding: 0.2rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .userName {
          font-size: 0.95rem;  
        }
        .emailWrp {
          padding: 0.2rem 0;
          font-size: 0.75rem;
        }
      }
    }
  }
}

// box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
