.multiTableWrapper {
  display: grid;
  flex-wrap: wrap;
  width: 100%;

  &:first-child {
    border-right: 1px solid;
  }
}

.bordered {
  border-right: 1px solid gray;
}
.topBordered {
  border-top: 1px solid gray;
}
.bothTable {
  grid-template-columns: auto auto;
}

.singleTable {
  grid-template-columns: auto;
}

@media screen and (max-width: 590px) {
  .multiTableWrapper {
    display: flex;
    flex-direction: column;
  }
}
