.navTabMenuHeader {
  display: none;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
  // .link {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   border-top-left-radius: 0.5rem;
  //   border-top-right-radius: 0.5rem;
  // }
  position: sticky;
  top: 0;
  z-index: 30;
}

@media screen and (max-width: 590px) {
  .navTabMenuHeader {
    display: grid;
  }
}
