.layoutWrapper {
  min-height: 100vh;
  max-width: 1920px;
  margin: auto;
  // background: red !important;
}

$width: 20%;

.contentWrapper {
  display: grid;
  grid-template-columns: $width calc(100% - ($width + $width)) $width;
  min-height: 90vh;
  .col {
    position: relative;
    // border: 1px solid gray;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 1220px) {
  .contentWrapper {
    grid-template-columns: 25% calc(100% - (25% + 25%)) 25%;
  }
}

@media screen and (max-width: 1024px) {
  .contentWrapper {
    grid-template-columns: calc(100% - 35%) 35%;
    padding-left: 1rem;
    .col:first-child {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .contentWrapper {
    grid-template-columns: 100%;
    padding-right: 0.7rem;
    .col:last-child {
      display: none;
    }
  }
}

//  TODO: Refactor below all css
.authWrp {
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  min-height: 100vh;
  // background-color: red;
  .leftWrp {
    background-image: url("../assets/images/Login-Image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 991px) {
  .authWrp {
    grid-template-columns: 1fr;
    grid-template-rows: 300px 1fr;

    .leftWrp {
      background-image: url("../assets/images/Login-image1.png");
    }
  }
}

.imgWrapper {
  display: flex;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    display: inline-block;
  }
}

@media screen and (max-width:1200px) {
  .imgWrapper{
    img{
      width: 80%;
    }
  }
}