.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: row;
}

.loader {
  background-color: #262a53a8;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sm {
  font-size: 1.2rem;
}
.md {
  font-size: 1rem;
}
.defaultSize {
  font-size: 0.1rem;
}

.lottieLoaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
