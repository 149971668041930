.betSlipWrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  // top: 0;
  width: 20%;
  z-index: 104;
  .betSlipCard {
    height: 100%;
    &.show {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  box-shadow: -2px -2px 3px #12312388;
  border-radius: var(--radius-default);
  &.show {
    top: 20%;
  }
}

.hiddenBody {
  transition: 0.3s;
  height: 0;
  &.show {
    height: auto;
  }
}
.slipBody {
  // height: calc(100vh - 85px);
  transition: 0.3s;

  min-height: calc(100vh - 85px);
  overflow: auto;
}
.slipFooter {
  text-align: right;
}

.stickyTab {
  position: sticky;
  top: 0;
  z-index: 101;
}

.slipFooter {
  position: sticky;
  bottom: 0;
  z-index: 102;
}

// @media screen and (max-width: 1400px) {
//   .betSlipWrapper {
//     width: 25%;
//   }
// }

@media screen and (max-width: 1220px) {
  .betSlipWrapper {
    width: 25%;
  }
}

@media screen and (max-width: 1024px) {
  .betSlipWrapper {
    width: 34%;
  }
}
