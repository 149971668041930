.winPlaceSelection {
  // border: 1px solid gray;
  display: grid;
  // grid-template-columns: repeat(4, var(--width-selection));
  column-gap: var(--pool-col-gap);
  align-items: center;
  // padding: 0 0.25rem;
  .oddsBtn {
    width: 100%;
    height: 2.5rem;
    border-radius: var(--radius-default);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.308);
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .fav {
      position: absolute;
      top: -14px;
      left: -19px;
      width: 40px;
      font-size: 0.65rem;
      font-weight: 600;
      background: gold;
      color: black;
      display: inline-block;
      padding: 1rem 0.5rem 0 0.5rem;
      transform: rotate(315deg);
      @media screen and (max-width: 768px) {
        font-size: 0.35rem;
        top: -13px;
        left: -20px;
      }
    }
  }
}

.exoticsSelection {
  display: grid;
  // grid-template-columns: repeat(4, 40px);
  align-items: center;
  justify-items: center;
}

.odds {
  font-size: 0.8rem;
}

@media screen and (max-width: 425px) {
  .winPlaceSelection {
    .oddsBtn {
      height: 29px;
      font-size: 0.6rem;
    }
  }
}
